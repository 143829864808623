<script setup lang="ts">
const props = defineProps<{
  data: {
    items: {
      block_pool_animation_items_id: {
        translations?: DirectusTranslation<{
          title: string
          description: string
          button_text?: string
          button_link?: string
        }>
      }
    }[]
  }
}>()
const videoRef = ref<HTMLVideoElement>()
const { progress } = animateHomeUspBackgroundVideo(videoRef)

const _progressBarPercentage = computed(() => {
  return `${progress.value * 100}%`
})

const contentBlocks = computed(() => props.data.items.map(item => dt(item.block_pool_animation_items_id.translations)))

const progressIndex = computed(() => {
  if (progress.value === 1)
    return contentBlocks.value.length - 1
  return Math.floor((progress.value * (contentBlocks.value.length)))
})
</script>

<template>
  <BlockBase id="videoContainer" class="relative mb-12 min-h-screen">
    <div class="pointer-events-none absolute inset-0">
      <!-- Video -->
      <video ref="videoRef" class="h-full w-full object-cover" src="~/assets/videos/home-usp-background.mp4" preload="auto" disablepictureinpicture muted webkit-playsinline playsinline type="video/mp4" />
      <!-- Dark Overlay -->
      <div class="absolute inset-0 bg-starline-secondary/40" />
      <!-- Gradients -->
      <div class="gradient-top-to-bottom absolute inset-x-0 bottom-85% top-0" />
      <div class="gradient-bottom-to-top absolute inset-x-0 bottom-0 top-85%" />
      <div class="gradient-left-to-right absolute inset-y-0 left-0 right-85% hidden md:block" />
      <div class="gradient-right-to-left absolute inset-y-0 left-85% right-0 hidden md:block" />
    </div>
    <Container class="relative h-full w-full flex flex-col items-center justify-center gap-20 py-20">
      <div class="grid-stack">
        <div
          v-for="(block, index) in contentBlocks"
          :key="index"
          class="m-auto flex flex-col items-center transition-all duration-500ms ease-in-out"
          :class="{
            'opacity-0 blur-[10rem] pointer-events-none': progressIndex !== index,
            'opacity-100 blur-0': progressIndex === index,
          }"
        >
          <h1 class="mb-2rem max-w-90rem text-center text-balance textH1">
            {{ block?.title }}
          </h1>
          <p class="mb-3.5rem max-w-47rem text-center text-balance textNormal">
            {{ block?.description }}
          </p>
          <BtnPrimary v-if="block?.button_link && block?.button_text" :link="block?.button_link" :text="block?.button_text" />
        </div>
      </div>
      <!-- Progress Bar -->
    </Container>
    <div class="absolute inset-x-0 bottom-4rem left-0 grid place-items-center">
      <div class="relative mt-5rem h-2px max-w-40rem w-full flex gap-8 px-8">
        <div v-for="(block, index) in contentBlocks" :key="index" class="w-full">
          <div
            class="h-2px w-full bg-neutral-darkGray transition"
            :class="{
              'bg-starline-primary': index <= progressIndex,
            }"
          />
        </div>
      </div>
    </div>
  </BlockBase>
</template>

<style scoped>
.gradient-left-to-right {
  background: linear-gradient(to right, #000b1a 30%, transparent 100%);
}

.gradient-right-to-left {
  background: linear-gradient(to left, #000b1a 30%, transparent 100%);
}

.gradient-top-to-bottom {
  background: linear-gradient(to bottom, #000b1a 30%, transparent 100%);
}

.gradient-bottom-to-top {
  background: linear-gradient(to top, #000b1a 30%, transparent 100%);
}
</style>
